// Margin Bottom
@for $i from 1 through 40 {
	.mb-#{$i} {
		margin-bottom: #{$i}px !important;
	}
}

// Margin Top
@for $i from 1 through 40 {
	.mt-#{$i} {
		margin-top: #{$i}px !important;
	}
}

// Font Size
@for $i from 1 through 40 {
	.fs-#{$i} {
		font-size: #{$i}px !important;
	}
}
