@import "assets/scss/vars.scss";
@import "assets/scss/generate";

body {
	margin: 0;
	font-family: "Nunito", sans-serif, VideoJs, fcicons;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	#{$rtl} & {
		font-family: "Cairo", sans-serif, VideoJs, fcicons !important;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
