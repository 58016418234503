@import "assets/scss/vars.scss";
@import "assets/scss/_generate.scss";

// COLORS
:root {
	--green: #14a800;
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

* {
	font-family: "Nunito", sans-serif, VideoJs, fcicons !important;

	#{$rtl} & {
		font-family: "Cairo", sans-serif, VideoJs, fcicons !important;
	}
}

html {
	scroll-behavior: smooth;
}

body {
	font-family: "Nunito", sans-serif, VideoJs, fcicons;
	// overflow-x: hidden;
	padding: 0;
	margin: 0;
	text-align: start;
	min-height: 100vh;

	#{$rtl} & {
		font-family: "Cairo", sans-serif, VideoJs, fcicons !important;
	}
}

#root {
	height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	padding: 0;
	margin: 0;
}

a {
	text-decoration: none !important;
	transition: all 0.3s;
}

*::-webkit-input-placeholder {
	color: #a0aec0;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	font-size: 14px;
	font-weight: 600;
}

*::-moz-placeholder {
	color: #a0aec0;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	font-size: 14px;
	font-weight: 600;
}

*:-ms-input-placeholder {
	color: #a0aec0;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
	font-size: 14px;
	font-weight: 600;
}

*::-ms-input-placeholder {
	color: #a0aec0;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
	font-size: 14px;
	font-weight: 600;
}

*::placeholder {
	color: #a0aec0;
	transition: all 0.3s ease;
	font-size: 14px;
	font-weight: 600;
}

ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

/* Custom Scroll bar */
body::-webkit-scrollbar,
*::-webkit-scrollbar {
	width: 0.5em;
	height: 0.3em;
}

body::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: $primarySoftColor;
}

body::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
	background-color: $grayColor;
	outline: 1px solid $primarySoftColor;
}

/* custom scrollbar for firefox */
@-moz-document url-prefix() {
	*,
	html,
	body {
		/* "auto" or "thin"  */
		scrollbar-width: thin;
		/* scroll thumb & track */
		scrollbar-color: $grayColor $primarySoftColor;
	}
}

// My Style
.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: #f8f9fc;
	overflow: initial;
}

main {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
}

input {
	outline: 0 none;
}

// Input
.inputGroup {
	display: flex;
	flex-direction: column;
	row-gap: 8px;

	.label {
		font-size: 14px;
		font-weight: 600;
		color: $mainColor;
		position: relative;

		&.required {
			&::after {
				content: "*";
				color: $redColor;
				-webkit-margin-start: 6px;
				margin-inline-start: 6px;
				display: inline-block;
			}
		}
	}

	.input {
		width: 100%;
		padding: 12px;
		background-color: #fff;
		border: 1px solid #dce0e6;
		border-radius: 6px;
		transition: 0.3s;
		outline: 0 none;

		&:hover,
		&:focus,
		&:focus-visible {
			border-color: rgba(20, 168, 0, 0.6);
		}
	}

	.input_hint {
		font-size: 12px;
		font-weight: 500;
		color: $grayColor;
	}
}

// OR
.or {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: 16px 0;

	span {
		font-size: 10px;
		font-weight: 400;
		color: $secondaryColor;

		&::before,
		&::after {
			content: "";
			height: 1px;
			width: 100%;
			background-color: #dce0e5;
			max-width: 40%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		&::before {
			left: 0;
		}

		&::after {
			right: 0;
		}
	}
}

// Buttons
.MuiButton-root {
	min-width: auto !important;
	line-height: normal !important;
}

// Word With Rectangle
.withRect {
	display: inline-block;
	position: relative;
	z-index: 0;

	&::before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 20px;
		background-color: #ffecb3;
		z-index: -1;
	}
}

// Title
.big_title {
	text-align: center;
	font-size: 36px;
	font-weight: 800;
	color: $mainColor;
	line-height: 58px;
	margin-bottom: 24px;

	@media (max-width: 600.98px) {
		font-size: 24px;
		line-height: normal;
	}
}

// Green Divider Line
.divider_line {
	width: 50px;
	height: 2px;
	background-color: #43b933;
	margin-bottom: 12px;

	// Gray Line
	&.grayLine {
		background-color: #dce0e5;
	}
}

// Icon
.MuiButton-startIcon {
	#{$rtl} & {
		margin-right: -4px;
		margin-left: 8px;
	}
}

// Input Type search Clear Icon [x]
[type="search"]::-webkit-search-cancel-button {
	color: $greenColor;
	-webkit-appearance: none;
	appearance: none;
	background-image: url("../public/img/close.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 10px;
	height: 10px;
	cursor: pointer;
}

// Table
.MuiTableCell-root {
	text-align: start !important;
}

// Div Holder
.divHolder {
	background-color: $whiteColor;
	box-shadow: 0px 4px 16px rgba(72, 94, 144, 0.06);
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	padding: 20px 30px;

	&:not(:last-child) {
		margin-bottom: 20px;
	}
}

.gif_loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
}

// Tabs Wrapper
.tabs_wrapper {
	margin-bottom: 32px;
	.MuiTabs-root {
		overflow: initial;

		.MuiTabs-flexContainer {
			justify-content: center;
			align-items: center;
			gap: 20px;
			flex-wrap: wrap;

			@media (max-width: 1063.98px) {
				gap: 10px;
			}

			.MuiButtonBase-root {
				padding: 10px 20px;
				background-color: $whiteColor;
				border-radius: 100px;
				-webkit-border-radius: 100px;
				-moz-border-radius: 100px;
				-ms-border-radius: 100px;
				-o-border-radius: 100px;
				box-shadow: 0px 0px 1px rgba(2, 14, 31, 0.24),
					0px 3px 8px -1px rgba(2, 14, 31, 0.05);
				flex-direction: row;
				gap: 10px;
				text-transform: capitalize;
				min-height: 40px;
				color: $mainColor;
				font-size: 14px;
				font-weight: 600;
				line-height: normal;
				transition: 0.3s;
				-webkit-transition: 0.3s;
				-moz-transition: 0.3s;
				-ms-transition: 0.3s;
				-o-transition: 0.3s;

				.MuiSvgIcon-root {
					margin-bottom: 0;
					fill: $borderColor;
					transition: 0.3s;
					-webkit-transition: 0.3s;
					-moz-transition: 0.3s;
					-ms-transition: 0.3s;
					-o-transition: 0.3s;
					max-width: 20px;
				}

				.MuiTab-iconWrapper {
					margin-bottom: 0;
				}

				.arabic_icon {
					width: 20px;
					height: 20px;
					border-radius: 100%;
					-webkit-border-radius: 100%;
					-moz-border-radius: 100%;
					-ms-border-radius: 100%;
					-o-border-radius: 100%;
					background-color: $borderColor;
					display: flex;
					align-items: baseline;
					justify-content: center;
					color: $whiteColor;
					font-weight: 600;
					transition: 0.3s;
					-webkit-transition: 0.3s;
					-moz-transition: 0.3s;
					-ms-transition: 0.3s;
					-o-transition: 0.3s;
				}

				.france_icon {
					width: 20px;
					height: 20px;
					position: relative;
					transition: 0.3s;
					-webkit-transition: 0.3s;
					-moz-transition: 0.3s;
					-ms-transition: 0.3s;
					-o-transition: 0.3s;

					.fr_icon {
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						-webkit-transform: translate(-50%, -50%);
						-moz-transform: translate(-50%, -50%);
						-ms-transform: translate(-50%, -50%);
						-o-transform: translate(-50%, -50%);
						font-size: 10px;
						color: $whiteColor;
						font-weight: 600;
						transition: 0.3s;
						-webkit-transition: 0.3s;
						-moz-transition: 0.3s;
						-ms-transition: 0.3s;
						-o-transition: 0.3s;
					}
				}

				&.Mui-selected {
					background-color: $greenColor;
					color: $whiteColor;

					.MuiSvgIcon-root {
						fill: $whiteColor;
					}

					.arabic_icon {
						background-color: $whiteColor;
						color: $greenColor;
					}

					.france_icon {
						.fr_icon {
							color: $greenColor;
						}
					}
				}
			}
		}

		.MuiTabs-scroller {
			overflow: initial !important;
		}

		.MuiTabs-indicator {
			display: none;
		}
	}
}

.removePadding {
	padding: 0 !important;
}

// Back Button
.back_Button {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin-bottom: 20px !important;
	align-self: flex-start;
}

// Circular Progress
.circular_progress {
	margin: 30px auto;
	display: block !important;
}

// Width: 100%
.w-100 {
	width: 100%;
}

.maxWidth-100 {
	max-width: 100% !important;
}

// Flex Grow 1
.flexGrow_1 {
	flex-grow: 1;
}

// Flex Shrink 0
.flexShrink_0 {
	flex-shrink: 0;
}

// Margins
.mb-20 {
	margin-bottom: 20px;
}

.mt-16 {
	margin-top: 16px !important;
}

// Generate Margin Bottom
@for $i from 1 through 60 {
	.mb-#{$i} {
		margin-bottom: #{$i}px;
	}
}

// Modal Title With Back Btn
.modalTitle_btn {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;

	.title_btn {
		flex-shrink: 0;
		padding: 0;
		width: 24px;
		height: 24px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.modal_title {
		@include font(22px, 700, $darkColor);
		text-transform: capitalize;
	}
}

// SVG
svg {
	margin-bottom: 0 !important;
}

// Height 100%
.h100 {
	height: 100%;
}

// Margin Inline Start
.mis-auto {
	-webkit-margin-start: auto !important;
	margin-inline-start: auto !important;
}

.ant-spin-dot i {
	background-color: #14a800 !important;
}

// h1
.h1 {
	font-size: 24px;
	font-weight: 800;
	color: #1f1f20;
	margin-bottom: 25px;
}

// Card Holder
.cardHolder {
	padding: 24px 40px;
	background-color: #fff;
	box-shadow: 0px 2px 6px 0px #0d0a2c14;
	@include borderRadius(20px);

	@media (max-width: 600.98px) {
		padding: 20px 15px;
	}
}

// Tabs Holder
.tabsHolder {
	.MuiTabs-root {
		.MuiTab-root {
			@include font(14px, 400, #000 !important);
			padding: 17px 16px !important;
			text-transform: capitalize;

			@media (max-width: 600.98px) {
				font-size: 10px;
				padding: 6px 20px !important;
			}

			&.Mui-selected {
				font-weight: 700 !important;
				color: $greenColor !important;
			}
		}

		.MuiTabs-indicator {
			background-color: $greenColor !important;
			height: 3px;
			@include borderRadius(20px);
		}
	}

	.MuiTabPanel-root {
		padding: 0 !important;
	}
}

.one-line-text {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
}

.flexShrink-0 {
	flex-shrink: 0;
}
